<!-- 短剧 -->
<template>
  <div class="playlet">
    <!-- 专题组合样式(组合样式) -->
    <VideoModuleItem :isAcg="true" :patternType="1" moreBtnTxt="查看更多" halvingLineColor="rgba(255, 255, 255, 0.02)" :subModuleId="itemModules.id" :moduleSort ="1" v-if="itemModules"></VideoModuleItem>
  </div>
</template>

<script>
  import VideoModuleItem from "@/components/videoModuleItem/index.vue";

  export default {
    name: 'Playlet',
    components: {VideoModuleItem},
    data(){
      return {
        itemModules: null,
      }
    },
    mounted() {
      let modulesList = this.$store.getters.modulesInfo?.shortPlay || [];
      if(modulesList && modulesList.length > 0){
        this.itemModules = modulesList[0];
      }
    }
  }
</script>

<style scoped lang="scss">
  .playlet{

    /deep/ .coverBox{
      background: transparent !important;
      .videoInfo{
        .title{
          color: #FFFFFF !important;
        }
      }

      .cover{
        border-radius: 8px;
      }

      .van-image__img{
        border-radius: 8px;
      }
    }

    /deep/ .titleBox{
      .leftBox{
        .title{
          font-weight: 500;
          font-size: 16px;
          color: #FFFFFF !important;
        }
      }
    }

    /deep/ .videoModuleItem{
      height: calc(100vh - 40px);
      padding-top: 12px;

      .playletSwipe{
        margin-top: 0;
      }
    }

  }
</style>